/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/lightgallery@2.7.1/lightgallery.min.js
 * - /npm/lightgallery@2.7.1/plugins/thumbnail/lg-thumbnail.min.js
 * - /npm/lightgallery@2.7.1/plugins/video/lg-video.min.js
 * - /npm/lightgallery@2.7.1/plugins/fullscreen/lg-fullscreen.min.js
 * - /npm/lightgallery@2.7.1/plugins/zoom/lg-zoom.min.js
 * - /npm/lightgallery@2.7.1/plugins/share/lg-share.min.js
 * - /npm/lightgallery@2.7.1/plugins/autoplay/lg-autoplay.min.js
 * - /npm/lightgallery@2.7.1/plugins/comment/lg-comment.min.js
 * - /npm/lightgallery@2.7.1/plugins/pager/lg-pager.min.js
 * - /npm/lightgallery@2.7.1/plugins/hash/lg-hash.min.js
 * - /npm/lightgallery@2.7.1/plugins/rotate/lg-rotate.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
